<template>
  <b-modal
    v-model="modalMultipleInicioDeSesion"
    title="Multiple Inicio de Sesion"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    centered
  >
    <div class="row">
      <div class="col-sm-12 text-center">
        <i class="i-Information" style="font-size: 100px; color: #01f193"></i>
      </div>
      <div class="col-sm-12 text-center py-3">
        <span>
          Se ha detectado un inicio de sesión desde otra ubicación. Has clic en 'ACTUALIZAR' para iniciar sesión desde aquí.
        </span>
      </div>
      <div class="col-sm-12 text-center">
        <button class="btn th-custom-color px-4 rounded" @click="logout">
          <span>ACTUALIZAR</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/database';

const PATH_DOC_USERS = `${window.location.hostname.split('.').join('')}/users`;
const config = {
  apiKey: 'AIzaSyCnpzAYzdFHHFM6_zs_0h5TY1MoxpIidlo',
  authDomain: 'gemgloo-532bb.firebaseapp.com',
  databaseURL: 'https://gemgloo-532bb-default-rtdb.firebaseio.com',
  projectId: 'gemgloo-532bb',
  storageBucket: 'gemgloo-532bb.appspot.com',
  messagingSenderId: '95453986025',
  appId: '1:95453986025:web:2d5b5062c012004321c478',
  measurementId: 'G-T0L4M35G1L',
};
firebase.initializeApp(config);

export default {
  name: 'MultipleLoginControl',
  created() {
    this.setSessionID();
  },
  mounted() {
    this.db = firebase.database();
    this.setDataFirebase();
    this.db.ref(`${PATH_DOC_USERS}/${this.user.id}`).on('value', this.newInicioDeSesion);
  },
  computed: {
    ...mapState('main', [
      'user',
    ]),
  },
  data() {
    return {
      modalMultipleInicioDeSesion: false,
      session_id: '',
      db: null,
    };
  },
  methods: {
    ...mapActions([
      'signOut',
    ]),
    logout() {
      this.signOut();
      this.$router.push('/login');
    },
    generarToken(length) {
      const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
      const b = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        const j = (Math.random() * (a.length - 1)).toFixed(0);
        b[i] = a[j];
      }
      return b.join('');
    },
    setDataFirebase() {
      firebase
        .database()
        .ref(`${PATH_DOC_USERS}/${this.user.id}/session_id`)
        .set(this.getSessionID());
    },
    newInicioDeSesion(snapshot2) {
      const v = snapshot2.val();
      if (v) {
        if (v.session_id !== this.session_id) {
          this.modalMultipleInicioDeSesion = true;
        }
      }
    },
    setSessionID() {
      this.session_id = localStorage.getItem('sesion_id') ?? this.generarToken(32);
      localStorage.setItem('sesion_id', this.session_id);
    },
    getSessionID() {
      return localStorage.getItem('sesion_id');
    },
  },
};
</script>

<style scoped>

</style>
