<template>
  <div class="main-header">
    <div class="logo">
      <img :src="getPathLogo" alt />
    </div>
    <router-link v-for="(item, index) in getFuncinalidadesGo" :key="index"
      :to="item.ruta"
      class="text-black ml-3"
      active-class="activo"
    >
      <i
        class="h5 nav-item-icon"
        :class="item.icono"
      ></i>       <span class="nav-item-text">{{item.descripcion}}</span>
    </router-link>
    <b-navbar class="pl-2" v-if="getFuncinalidadesGoList.length > 0">
      <b-navbar-nav class="text-black">
        <b-nav-item-dropdown left>
          <template #button-content>
              <router-link to="" class="pr-3 h3">+</router-link>
          </template>
          <div v-for="(data, index) in getFuncinalidadesGoList" :key="index" class="py-1 px-3">
            <router-link :to="data.ruta" class="text-black" active-class="activo">
              <i
                class="h5"
                :class="data.icono"
              />
              {{data.descripcion}}
            </router-link>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <div style="margin: auto"></div>
    <div class="header-part-right">
      <!-- Full screen toggle -->
      <i
        class="i-Full-Screen header-icon d-none d-sm-inline-block"
        @click="handleFullScreen"
      ></i>
      <!-- Notification -->
      <!-- <div class="dropdown mr-1">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="badge-top-container"
          toggle-class="text-decoration-none"
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <i class="i-Bell text-muted header-icon"></i>
          </template>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            :class="{ open: getSideBarToggleProperties.isSideNavOpen }"
            ref="myData"
            class="dropdown-menu-right rtl-ps-none notification-dropdown  ps scroll"
          >
            <div class="dropdown-item d-flex">
              <div class="notification-icon">
                <i class="i-Speach-Bubble-6 text-primary mr-1"></i>
              </div>
              <div class="notification-details flex-grow-1">
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-dropdown>
      </div> -->
      <!-- Notification End -->
      <!-- Mas opciones de Configuracion -->
      <div class="dropdown">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="m-md-2 user col align-self-end"
          toggle-class="text-decoration-none"
          right
          no-caret
          variant="link"
        >
          <template slot="button-content">
            <img
              :src="getPathImgUser"
              id="userDropdown"
              alt=""
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            />
          </template>

          <div
            class="dropdown-menu-right"
            aria-labelledby="userDropdown"
          >
            <div class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> {{user.name}}
            </div>
            <b-dropdown-item-button disabled>
              ID:{{company.id}} {{company.tradename}}
            </b-dropdown-item-button>
            <router-link
              v-for="item in getFuncionalidadesDropDown"
              :key="item.id"
              :to="item.ruta"
              class="dropdown-item"
              active-class="activo"
            >
              {{item.descripcion}}
            </router-link>
            <!-- Salir -->
            <a href="#" class="text-dark mr-5" @click.prevent="logoutUser">
              <svg width="4.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-door-open mb-1"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0
                  1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z"/>
                <path fill-rule="evenodd" d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6
                  .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z"/>
                <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z"/>
              </svg>
              <b class="pl-1">Salir</b>
            </a>
            <!-- Fin Salir -->
          </div>
        </b-dropdown>
      </div>
    </div>
  </div>

  <!-- header top menu end -->
</template>
<script>
import Util from '@/utils';
import { isMobile } from 'mobile-device-detect';
import { mapGetters, mapActions, mapState } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  components: {
  },

  data() {
    return {
      isDisplay: true,
      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false,
    };
  },
  mounted() {
    // document.addEventListener("click", this.closeMegaMenu);
  },
  computed: {
    ...mapGetters(['getSideBarToggleProperties']),
    ...mapGetters('main', [
      'getPathLogo',
      'getPathImgUser',
      'getFuncionalidadesTopNav',
      'getFuncionalidadesDropDown',
    ]),
    ...mapState('main', [
      'user',
      'company',
    ]),
    getFuncinalidadesGo() {
      const items = this.getFuncionalidadesTopNav;
      const datas = [];
      items.forEach((element, i) => {
        if (i < 9) {
          datas.push(element);
        }
      });
      return datas;
    },
    getFuncinalidadesGoList() {
      const items = this.getFuncionalidadesTopNav;
      const datas = [];
      items.forEach((element, i) => {
        if (i >= 9) {
          datas.push(element);
        }
      });
      return datas;
    },
  },

  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSidebarProperties',
      'changeThemeMode',
      'signOut',
    ]),

    // megaMenuToggle() {
    //   this.isMegaMenuOpen = false;

    //   console.log("work");
    // },

    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logoutUser() {
      this.signOut();
      this.$router.push('/login');
    },

    closeMegaMenu() {
      this.isMegaMenuOpen = false;
      // console.log(this.isMouseOnMegaMenu);
      // if (!this.isMouseOnMegaMenu) {
      //   this.isMegaMenuOpen = !this.isMegaMenuOpen;
      // }
    },
    toggleMegaMenu() {
      this.isMegaMenuOpen = !this.isMegaMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle() {
      if (
        this.getSideBarToggleProperties.isSideNavOpen
        && this.getSideBarToggleProperties.isSecondarySideNavOpen
        && isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
        this.getSideBarToggleProperties.isSideNavOpen
        && this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen
        && !this.getSideBarToggleProperties.isSecondarySideNavOpen
        && !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
        !this.getSideBarToggleProperties.isSideNavOpen
        && !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        // console.log("4");

        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
        // console.log("4");
      }
    },
  },
};
</script>

<style scoped>
.text-black{
  color:black;
}
.activo{
  color:#01a9db;
}
/* Para pantallas más grandes */
@media (min-width: 1275px) {
  .nav-item-text {
    font-size: 0.8rem; /* Tamaño normal de la fuente */
  }
}
/* Para pantallas medianas */
@media (max-width: 1162px) and (min-width: 1120px) {
  .nav-item-text {
    font-size: 0.7rem; /* Reducir el tamaño de la fuente */
  }
}

@media (max-width: 1119px) and (min-width: 981px) {
  .nav-item-text {
    font-size: 0.5rem; /* Reducir el tamaño de la fuente */
  }
}

@media (max-width: 980px) and (min-width: 667px) {
  .nav-item-text {
    display: none; /* Oculta el texto */
  }
  .nav-item-icon {
    font-size: 1rem; /* Aumenta el tamaño del ícono si es necesario */
  }
}
/* Para pantallas más pequeñas */
@media (max-width: 666px) {
  .nav-item-text {
    display: none; /* Oculta el texto */
  }

  .nav-item-icon {
    font-size: 1rem; /* Aumenta el tamaño del ícono si es necesario */
  }
}

/* Evitar que la imagen del logo se desplace hacia abajo */
.logo img {
  max-width: 100%; /* Asegura que la imagen del logo no se desborde */
  height: auto;
}

</style>
