<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <top-nav />
    <multiple-login-control v-if="isModeProduction" />
    <main
      class="main-content-wrap pb-4"
      :class="isRouteModuloCalendario? 'bg-calendar':'bg-main'"
    >
      <transition name="page" mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import MultipleLoginControl from '@/components/util/MultipleLoginControl.vue';
import calendarioRoutes from '@/modules/calendario/routes/calendarioRoutes';
import TopNav from './TopNav.vue';

export default {
  components: {
    MultipleLoginControl,
    TopNav,
  },
  computed: {
    isModeProduction() {
      return process.env.NODE_ENV === 'production';
    },
    isRouteModuloCalendario() {
      return this.$route.name === calendarioRoutes[0].name;
    },
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>
<style lang="scss">
$orange:  #f5a330;
$orange-focus: #c78426;
$light-gray: #f0f0f0;

/* sobrescritura de clases de bootstrap */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
/* end */

/* clases personalizadas para b-modal */
.modal-custom-xl {
  max-width: 1140px;
}
/* como usar => <b-modal ... size='custom-xl'> */
.modal-custom-lg-max {
  max-width: 990px;
}
/* como usar => <b-modal ... size='custom-lg-max'> */
.modal-custom-md-max {
  max-width: 690px;
}
/* end */

/* clases personalizadas del proyecto */

/* text */
.text-orange {
  color: $orange !important;
}

/* button */
.btn-orange {
  background: $orange;
}
.btn-orange:focus:active {
  background-color: $orange-focus;
  box-shadow: 0 0 0 0.2rem rgba($orange, 0.6);
}
.btn-orange:hover {
  background: $orange;
  box-shadow: 0 8px 25px -8px $orange;
  border-color: $orange;
}

.btn-light-gray {
  background: $light-gray;
}
.btn-light-gray:focus:active {
  background-color: $light-gray;
  box-shadow: 0 0 0 0.2rem rgba($light-gray, 0.6);
}
.btn-light-gray:hover {
  background: $light-gray;
  box-shadow: 0 8px 25px -8px $light-gray;
  border-color: $light-gray;
}

/* background */
.bg-main{
  background:#fff !important;
}
.bg-calendar {
  background: #d3d9e6 !important;
}
.bg-orange{
  background: $orange !important;
}
.bg-light-gray {
  background: $light-gray;
}

.page-item.active .page-link {
  z-index: 1;
  color: black;
  background-color: #2efe9a !important;
  border-color: #2efe9a !important;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-select:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #66afe9;
}
.bg-custom-green{
  color: black;
  background-color: #2efe9a;
}
.custom-border-top{
  border-top: 1px solid #2efe9a !important;
}
.th-custom-color{
  background-color: #2efe9a;
}

/* b-Table */
.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border: none;
}
.table.b-table > thead > tr > th[aria-sort=none]::before, .table.b-table > tfoot > tr > th[aria-sort=none]::before {
  float: right;
  content: "\2195";
}
.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: none;
}
.table.b-table > thead > tr > th[aria-sort][aria-sort="descending"]::before, .table.b-table > tfoot > tr > th[aria-sort][aria-sort="descending"]::before {
  float: right;
  content: "\2193";
}
.table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: none;
}
.table.b-table > thead > tr > th[aria-sort][aria-sort="ascending"]::before, .table.b-table > tfoot > tr > th[aria-sort][aria-sort="ascending"]::before {
  float: right;
  content: "\2191";
}
.table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: none;
}

/* DropDown */
.dropdown-item:active
{
  background-color: #2efe9a;
  color:black;
}

/* b-tabs */
.nav-tabs {
  border-bottom: 1px solid var(--warning);
}
/* b-tab */
.nav-tabs .nav-item .nav-link.active {
  border: 1px solid transparent;
  background: rgba(255, 193, 7, 0.1);
  border-color: var(--warning) var(--warning) #fff;
}
/* Loaders */
.screen-center {
  transform: translateY(27vh);
}
/* padding */
.pt-5rem {
  padding-top: 5rem !important;
}
</style>
